$orange: #ff7e0f;
$text: #222;
$text-light: #555;
$text-extra-light: #99a5b4;
$bg-light: #f7f7f7;
$yellow: #ffdd68;
$blue: #3b8bed;
$green: #98dc74;
$pink: #ff87bf;
$purple: #b68af6;
$bg-dark: #2f2f2f;
