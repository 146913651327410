@mixin blocStyle {
  background-color: white;
  box-shadow: 0 2px 8px 0 rgba(204, 204, 204, 0.5);
  border-radius: 8px;
}

@mixin formInput {
  height: 40px;
  margin-bottom: 1rem;
  width: 100%;
  outline: none;
  border: 1px solid $orange;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(204, 204, 204, 0.5);
  padding-left: 1rem;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

@mixin hover {
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px,
      rgba(0, 0, 0, 0.07) 0px 32px 64px;
  }
}

@mixin btnOrange {
  background-color: $orange;
  padding: 15px 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.83px;
  color: white;
  cursor: pointer;
  border: none;
  box-shadow: 0 2px 8px 0 rgba(204, 204, 204, 0.5);

  @include hover;
}

@mixin scaleUp {
  animation: scaleUp 0.2s forwards;
}

@mixin loader($color) {
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  box-shadow: 0 -3em $color, 2.25em -2.25em rgba($color, 0.9), 3em 0 rgba($color, 0.7),
    2.25em 2.25em rgba($color, 0.6), 0 3em rgba($color, 0.5), -2.25em 2.25em rgba($color, 0.3),
    -3em 0 rgba($color, 0.2), -2.25em -2.25em rgba($color, 0.1);
  animation: spin 1.2s linear infinite;

  @keyframes spin {
    100% {
      transform: rotate(-360deg);
    }
  }
}

@mixin blurBg {
  position: fixed;
  background: rgba(107, 48, 0, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999999;
}
