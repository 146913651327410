@import '../../scss/index.scss';

.header-navigation {
  width: 100%;

  &__list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    @include respond(tablet) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__link-item {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.72px;
    color: $text;
    list-style: none;
    min-height: 14px;
    text-decoration: none;

    @include respond(tablet) {
      padding: 1rem 0;
      font-size: 16px;
    }
  }
}