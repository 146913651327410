@import '../../scss/variable.scss';

.language-switch {
  height: 60px;
  display: flex;
  margin-left: 1.5rem;
  border-radius: 5px;
  z-index: 9999999999;

  &__container {
    position: relative;
  }

  &__dropDown {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    max-height: 60px;
    height: auto;
    width: 100%;
    transition: all 0.3s linear;
    list-style: none;
    z-index: 9999;
    border-radius: 0 0 5px 5px;
    padding: 0 3px .5rem;

    &--open {
      max-height: 200px;
      box-shadow: 0 0 20px 0 #dbdbdb;
      background-color: white;
    }
  }

  &__item {
    padding-top: 1rem;
    order: 2;

    &:last-child {
      border-bottom: none;
    }

    img {
      width: 26px;
      height: 26px;
      cursor: pointer;
    }

    &--active {
      order: 1;
    }
  }

  &__arrow {
    color: $orange;
    font-size: 30px;
    transition: transform 0.3s linear;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    left: -3px;

    &--open {
      transform: rotate(180deg);
    }
  }
}