@import "../../scss/index.scss";

.header {
  box-shadow: 0 2px 8px 0 rgba(235, 235, 235, 0.5);
  display: flex;
  width: 100%;
  height: 60px;

  .Container {
    height: 60px;
    width: 100%;
  }

  &__logo {
    img {
      width: 150px;
      height: 40px;
      z-index: 999999999;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    width: 100%;
  }

  &__wrapper {
    justify-content: space-between;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 0 0 58px;
    min-height: 60px;

    @include respond(tablet) {
      position: absolute;
      right: 100%;
      width: 100vw;
      height: 100vh;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 999999999999999;
      flex-direction: column-reverse;
      justify-content: center;
      background-color: $bg-light;
      transition: transform 0.3s;
      transform: translateX(100%);
      padding: 0;
      padding-bottom: 100px;

      &--open {
        transform: translateX(0%);
      }
    }
  }

  &__btn {
    @include btnOrange;
    height: 50px;
    white-space: nowrap;
    margin-top: 0;
    margin-left: 2rem;
    padding: 15px 40px;

    @include respond(tablet) {
      margin: 1rem 0 !important;
    }

    a {
      color: white;
    }
  }

  &__hamburger {
    display: none;
    z-index: 99999999999;
    color: $text;

    @include respond(tablet) {
      display: unset;
    }
  }

  &__userBtns {
    display: flex;
    align-items: center;
    margin-left: 12px;

    @include respond(tabletS) {
      margin-left: 0px;
    }

    a {
      border: 2px solid $orange;
      cursor: pointer;
      border-radius: 50%;
      height: fit-content;
      padding: 4px;
      display: flex;
      margin-right: 10px;

      @include respond(tabletS) {
        &:last-child {
          margin-right: 0px;
        }
      }

      svg {
        color: $orange;
        font-size: 20px;
      }
    }
  }
}
