$smallMobile: 375px;
$mobile: 600px;
$tabletS: 820px;
$tablet: 1194px;

@mixin respond($media) {
  @if $media==smallmobile {
    @media (max-width: $smallMobile) {
      @content;
    }
  } @else if $media==mobile {
    @media (max-width: $mobile) {
      @content;
    }
  } @else if $media==tabletS {
    @media (max-width: $tabletS) {
      @content;
    }
  } @else if $media==tablet {
    @media (max-width: $tablet) {
      @content;
    }
  }
}
