@import "../../scss/mediaQueries.scss";

.Container {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  height: 100%;

  @include respond(tablet) {
    padding: 0 1rem;
  }

  @include respond(tabletS) {
    padding: 0 5px;
  }
}
