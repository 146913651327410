* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", "Times New Roman", sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./fonts/montserrat-v18-latin-regular.eot");

  src: local(""), url("./fonts/montserrat-v18-latin-regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/montserrat-v18-latin-regular.woff2") format("woff2"),
    url("./fonts/montserrat-v18-latin-regular.woff") format("woff"),
    url("./fonts/montserrat-v18-latin-regular.ttf") format("truetype"),
    url("./fonts/montserrat-v18-latin-regular.svg#Montserrat") format("svg");
}

/* montserrat-500 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./fonts/montserrat-v18-latin-500.eot");

  src: local(""), url("./fonts/montserrat-v18-latin-500.eot?#iefix") format("embedded-opentype"),
    url("./fonts/montserrat-v18-latin-500.woff2") format("woff2"),
    url("./fonts/montserrat-v18-latin-500.woff") format("woff"),
    url("./fonts/montserrat-v18-latin-500.ttf") format("truetype"),
    url("./fonts/montserrat-v18-latin-500.svg#Montserrat") format("svg");
}

/* montserrat-600 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("./fonts/montserrat-v18-latin-600.eot");

  src: local(""), url("./fonts/montserrat-v18-latin-600.eot?#iefix") format("embedded-opentype"),
    url("./fonts/montserrat-v18-latin-600.woff2") format("woff2"),
    url("./fonts/montserrat-v18-latin-600.woff") format("woff"),
    url("./fonts/montserrat-v18-latin-600.ttf") format("truetype"),
    url("./fonts/montserrat-v18-latin-600.svg#Montserrat") format("svg");
}

/* montserrat-700 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./fonts/montserrat-v18-latin-700.eot");

  src: local(""), url("./fonts/montserrat-v18-latin-700.eot?#iefix") format("embedded-opentype"),
    url("./fonts/montserrat-v18-latin-700.woff2") format("woff2"),
    url("./fonts/montserrat-v18-latin-700.woff") format("woff"),
    url("./fonts/montserrat-v18-latin-700.ttf") format("truetype"),
    url("./fonts/montserrat-v18-latin-700.svg#Montserrat") format("svg");
}

/* montserrat-800 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  src: url("./fonts/montserrat-v18-latin-800.eot");
  font-display: swap;

  src: local(""), url("./fonts/montserrat-v18-latin-800.eot?#iefix") format("embedded-opentype"),
    url("./fonts/montserrat-v18-latin-800.woff2") format("woff2"),
    url("./fonts/montserrat-v18-latin-800.woff") format("woff"),
    url("./fonts/montserrat-v18-latin-800.ttf") format("truetype"),
    url("./fonts/montserrat-v18-latin-800.svg#Montserrat") format("svg");
}

.display-none {
  display: none;
}
