@import "../../scss/index.scss";

.pageLoader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: $orange;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999999999999999;

  &__container {
    @include respond(mobile) {
      transform: scale(0.4);
    }
  }

  &__loader {
    @include loader(white);
  }

  p {
    margin-top: 100px;
    font-weight: 700;
    color: white;
    min-height: 14px;

    @include respond(mobile) {
      margin-top: 50px;
      font-size: 14px;
    }
  }
}
