@import "../../scss/index.scss";

.footer {
  background-color: $bg-dark;
  padding: 46px 0 27px 0;
  height: fit-content;

  &__logo {
    width: 150px;
    height: 40px;
  }

  .Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 49px;
    border-bottom: 0.5px solid white;
    height: 100%;
    min-height: 245px;

    @include respond(tabletS) {
      max-width: 80%;
    }
  }

  &__nav {
    width: 650px;
    margin-top: 48px;
    min-height: 15px;
  }

  .header-navigation__link-item {
    color: white;
  }

  &__contact {
    padding: 12px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 2px $orange;
    border-radius: 20px;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 1px;
    cursor: pointer;
    z-index: 9999;
    background-color: transparent;
    color: $orange;
    margin-top: 3rem;
    min-height: 44px;
  }

  &__nav2 {
    margin-top: 29px;
    display: flex;
    align-items: center;
    min-height: 15px;
    justify-content: center;

    @include respond(tabletS) {
      flex-direction: column;
      margin-top: 47px;
    }

    &-item {
      color: white;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.62px;
      list-style: none;
      margin-right: 1.5rem;

      @include respond(tabletS) {
        margin-right: 0px;
        margin-bottom: 2rem;
        font-size: 16px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  &__copyright {
    text-align: center;
    color: white;
    opacity: 0.5;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.83px;
    margin-top: 30px;
    min-height: 16px;
  }
}
