@import "../../scss/variable.scss";

.Cookies {
  position: relative;
  min-height: 35px;
  background-color: $yellow;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  transition: transform 0.2s linear;
  padding: 5px 30px 5px 5px;

  &--close {
    transform: translateY(46px);
  }

  &__close {
    position: absolute;
    right: 8px;
    top: 8px;
    font-size: 18px;
    cursor: pointer;
  }

  p {
    a {
      margin-left: 3px;
      text-decoration: underline;
    }
  }
}
